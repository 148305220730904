import CollectionConfigInterface from "../lib/CollectionConfigInterface";
import * as Networks from "../lib/Networks";
import * as Marketplaces from "../lib/Marketplaces";
import whitelistAddresses from "./whitelist.json";

const CollectionConfig: CollectionConfigInterface = {
  testnet: Networks.ethereumTestnet,
  mainnet: Networks.ethereumMainnet,
  // The contract name can be updated using the following command:
  // yarn rename-contract NEW_CONTRACT_NAME
  // Please DO NOT change it manually!
  contractName: "HanzoAvatars",
  tokenName: "Hanzo Avatars",
  tokenSymbol: "HNZAVA",
  hiddenMetadataUri: "ipfs://bafybeigogldcdwnyjv2pgyqikatpb3n4gl7z4twwuw6cssn5wqmu4z3gei/hidden.json",
  maxSupply: 5000,
  whitelistSale: {
    price: 0.05,
    maxMintAmountPerTx: 2,
    whitelistSupply: 1500
  },
  preSale: {
    price: 100,
    maxMintAmountPerTx: 0,
  },
  publicSale: {
    price: 0.05,
    maxMintAmountPerTx: 500,
  },
  contractAddress: "0xf52AA35fa1213D97942db88f3473783407552DAe",
  walletConnectProjectId: "54b86bafe334a726226e97077e25c877",
  marketplaceIdentifier: "hanzo-avatars",
  marketplaceConfig: Marketplaces.openSea,
  whitelistAddresses,
  royaltyReceiver: "0x760401007f6dbCcdb925990f9B33D1bfe5B16417",
  royaltyNumerator: "1000",
  treasury: "0x760401007f6dbCcdb925990f9B33D1bfe5B16417",
};

export default CollectionConfig;
